import { useEffect, useState } from "react";
import { yupValidators } from "../../helpers/yupValidators";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import Alert from "./../Alert/Alert.component";
import { TFunderIdTypes } from "../../interfaces/user";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUserType } from "../../redux/selectors/userSelector";
import {appInsights} from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  name: yupValidators.fullName,
  businessName: yupValidators.generic({}),
  phoneNumber: yupValidators.phoneNumber,
  email: yupValidators.email,
  message: yupValidators.contactMessage,
});

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<null | string>(null);

  const funderType: TFunderIdTypes = useTypedSelector(selectUserType);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    unregister,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (funderType === 1) {
      unregister(["businessName"]);
    }
    //eslint-disable-next-line
  }, [funderType]);

  const onSubmit = async ({
    name,
    businessName,
    phoneNumber,
    email,
    message,
  }: {
    name: string;
    businessName: string;
    phoneNumber: string;
    email: string;
    message: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess(null);

    const reqBody = {
      name,
      company_name: businessName,
      phone_number: phoneNumber,
      email,
      message,
    };

    try {
      await postData(apiEndpoints.CONTACT_US, reqBody);
      setLoading(false);
      setSuccess(
        "Your message has been received. We will get back to you shortly."
      );
      reset();
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : ContactForm}
      })
    }
  };

  return (
    <div className="w-100">
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="card p-4">
        <h5 className="page-subtitle mb-0">Contact Support</h5>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength={60}
                placeholder="John Doe"
                label="Name"
                errors={errors.name}
                {...register("name")}
              />
            </div>
            {funderType === 2 && (
              <div className="col-md-6 col-12">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  placeholder="Uber Nigeria"
                  label="Business Name"
                  errors={errors.businessName}
                  {...register("businessName")}
                />
              </div>
            )}
            <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="08000000000"
                label="Phone Number"
                errors={errors.phoneNumber}
                {...register("phoneNumber")}
              />
            </div>
            <div className="col-md-6 col-12">
              <CustomInputField
                type="email"
                maxLength={128}
                placeholder="e.g johndoe@abc.com"
                label="Email Address"
                errors={errors.email}
                {...register("email")}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                maxLength={300}
                placeholder="Enter message here"
                label="Message"
                errors={errors.message}
                rows={5}
                bottomLabel="This field is limited to 300 characters"
                {...register("message")}
              />
            </div>

            <div className="col-12 text-right">
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm"
                disabled={loading}
              >
                Send Message
                {loading && <Loader />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
