import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import { encrypt } from "./../../helpers/encryptor";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { TUser } from "../../interfaces/user";

export const initialUserState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    session_timeout: null,
    funder_id: null,
    funder_type_id: null,
    lcy_wallet_id: null,
    usd_wallet_id: null,
    activated: false,
    business_name: "",
    country: "",
    phoneNoCountryDialCode: "",
    forcePasswordChange: false,
    kyc_resolved_lastname: "",
    kyc_resolved_firstname: "",
  },
  token: "",
  showBalance: true,
};

export interface IUser {
  user: TUser;
  token: string;
  showBalance: boolean;
}

let showBalance: boolean;

export const userReducer = produce(
  (state: IUser = initialUserState, action: TAction): IUser => {
    switch (action.type) {
      // Set During Login, After Signup
      case actionType.SET_LOGGED_IN_USER:
        state.showBalance = showBalance;
        state.user = { ...action.payload };
        return state;

      case actionType.SET_LOGGED_IN_USER_TOKEN:
        const newToken = encrypt(action.payload);
        state.token = action.payload;
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, newToken);
        return state;

      case actionType.SHOW_BALANCE:
        state.showBalance = action.payload;
        return state;

      // Set During Logout
      case actionType.CLEAR_LOGGED_IN_USER:
        showBalance = state.showBalance;
        state = initialUserState;
        localStorage.clear();
        return state;
      default:
        return state;
    }
  }
);
