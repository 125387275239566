import React, { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData, postData } from "../../apis/apiMethods";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "../../redux/selectors/userSelector";
import { useActions } from "../../hooks/redux-hooks/useActions";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  kyc_number: yupValidators.genericRequired({
    message: "Please enter your Business Registration Number",
    max: 20,
  }),
});

interface IBusinessKYC {
  funder: any;
}
const BusinessKYC: React.FC<IBusinessKYC> = ({ funder }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = useTypedSelector(selectUser);
  const { setCurrentUser } = useActions();
  const [isUpdated, setIsUpdated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ kyc_number }: { kyc_number: string }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      kyc_number,
      country_code: funder?.country,
      kyc_type: 2,
    };

    try {
      await postData(apiEndpoints.UPDATE_USER_KYC, reqBody);

      const {
        data: { kyc_resolved_lastname, kyc_resolved_firstname },
      } = await getData(apiEndpoints.USER);
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({
          ...user,
          activated,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
        });
      }
      setSuccess("KYC information updated successfully.");
      setLoading(false);
      setIsUpdated(true);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "BusinessKYC" },
      });
    }
  };

  return (
    <div className="mt-4">
      <h5 className="page-subtitle">KYC Information</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert message={error} />}
        {success && <Alert message={success} type="success" />}
        <div className="row mt-4">
          <div className="col-12 col-md-6">
            <CustomInputField
              type="text"
              maxLength={20}
              {...register("kyc_number")}
              placeholder="e.g. ABC123"
              label="Business Reg. Number / RC Number"
              errors={errors.kyc_number}
              defaultValue={funder?.rc_number}
              readOnly={funder?.rc_number}
            />
          </div>
        </div>
        {(!funder?.rc_number || isUpdated) && (
          <button
            type="submit"
            className="btn btn-sm advancly-white-btn"
            disabled={loading}
          >
            Update
            {loading && <Loader />}
          </button>
        )}
      </form>
    </div>
  );
};

export default BusinessKYC;
