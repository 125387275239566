import { Fragment, useEffect, useState } from "react";
import {
  useRouteMatch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import ProfileSettingsLanding from "./ProfileSettingsLanding/ProfileSettingsLanding";
import { ROUTES } from "../../helpers/routes";
import { ProfileSettingsGovId } from "./ProfileSettingsGovId/ProfileSettingsGovId";
import { ProfileSettingsProofOfAddressIndividual } from "./ProfileSettingsProofOfAddressIndividual/ProfileSettingsProofOfAddressIndividual";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { TFunderIdTypes } from "../../interfaces/user";
import {
  selectForcePasswordChange,
  selectUser,
  selectUserType,
} from "../../redux/selectors/userSelector";
import { ProfileSettingsProofOfAddressCorporate } from "./ProfileSettingsProofOfAddressCorporate/ProfileSettingsProofOfAddressCorporate";
import { ProfileSettingsBusinessDocument } from "./ProfileSettingsBusinessDocument/ProfileSettingsBusinessDocument";
import Alert from "../../components/Alert/Alert.component";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { message } from "antd";

type TActiveTab =
  | "profile"
  | "business"
  | "banking"
  | "security"
  | "identity"
  | "nextOfKin";

const ProfileSettings = () => {
  const forcePasswordChange = useTypedSelector(selectForcePasswordChange);
  const [activeTab, setActiveTab] = useState<TActiveTab>("profile");
  const match = useRouteMatch();
  const history = useHistory();
  const funderType: TFunderIdTypes = useTypedSelector(selectUserType);
  const { isBvnValidated } = useTypedSelector(selectUser);

  const setCorrectTab = (tab: TActiveTab) => {
    history.push(match.path);
    setActiveTab(tab);
  };
  const { search } = useLocation();
  useEffect(() => {
    if (!isBvnValidated && localStorage.getItem("bvnalert")) {
      setActiveTab("banking");
    }
    const newActiveTab = search?.split("activeTab=")[1] as TActiveTab;
    if (newActiveTab) setActiveTab(newActiveTab);
    return () => localStorage.removeItem("bvnalert");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    appInsights.trackPageView({
      name: "ProfileSettings.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (forcePasswordChange) {
      message.error("Please update your password to proceed.");
      setActiveTab("security");
    } else if (!isBvnValidated && localStorage.getItem("bvnalert")) {
      setActiveTab("banking");
      message.warning("Verify your BVN");
    }
  }, [forcePasswordChange]);
  return (
    <Fragment>
      <div className="page-title mb-3">Profile Settings</div>
      <div className="mt-3">
        <div className="alignToggleTabItems">
          <ToggleTab
            text="My Profile"
            id="profile"
            activeTab={activeTab}
            setActiveTab={(activeTab) => setCorrectTab(activeTab)}
          />
          {funderType === 2 && (
            <ToggleTab
              text="Business Information"
              id="business"
              activeTab={activeTab}
              setActiveTab={(activeTab) => setCorrectTab(activeTab)}
            />
          )}
          <ToggleTab
            text="Banking Information"
            id="banking"
            activeTab={activeTab}
            setActiveTab={(activeTab) => setCorrectTab(activeTab)}
          />
          <ToggleTab
            text="Identity"
            id="identity"
            activeTab={activeTab}
            setActiveTab={(activeTab) => setCorrectTab(activeTab)}
          />
          {funderType === 1 && (
            <ToggleTab
              text="Next of Kin"
              id="nextOfKin"
              activeTab={activeTab}
              setActiveTab={(activeTab) => setCorrectTab(activeTab)}
            />
          )}
          <ToggleTab
            text="Security"
            id="security"
            activeTab={activeTab}
            setActiveTab={(activeTab) => setCorrectTab(activeTab)}
          />
        </div>
      </div>
      <Route
        exact
        path={match.path}
        render={() => <ProfileSettingsLanding activeTab={activeTab} />}
      />
      <Route
        exact
        path={ROUTES.PROFILE_SETTINGS_ADDRESS_PROOF_INDIVIDUAL}
        component={ProfileSettingsProofOfAddressIndividual}
      />
      <Route
        exact
        path={ROUTES.PROFILE_SETTINGS_GOV_ISSUED_ID}
        component={ProfileSettingsGovId}
      />
      <Route
        exact
        path={ROUTES.PROFILE_SETTINGS_ADDRESS_PROOF_CORPORATE}
        component={ProfileSettingsProofOfAddressCorporate}
      />
      <Route
        exact
        path={ROUTES.PROFILE_SETTINGS_BUSINESS_DOCUMENT}
        component={ProfileSettingsBusinessDocument}
      />
    </Fragment>
  );
};

export default ProfileSettings;
