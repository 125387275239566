import React, { Fragment, useState } from "react";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { TWithdrawalTypes } from "../../../interfaces/withdrawal";
import styles from "./WithdrawalModalStepTwo.module.scss";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import { selectWithdrawal } from "./../../../redux/selectors/withdrawalSelector";
import useFunder from "./../../../hooks/custom-hooks/useFunder";
import Loader from "../../Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";

interface IWithdrawModalStepTwo {
  closeModal: () => void;
}

export const WithdrawalModalStepTwo: React.FC<IWithdrawModalStepTwo> = ({
  closeModal,
}) => {
  const [type, setType] = useState<TWithdrawalTypes>("");
  const { setWithdrawalInfo } = useActions();
  const withdrawalInfo = useTypedSelector(selectWithdrawal);

  const { data, isLoading, isError, error, refetch, isFetching } = useFunder();
  return (
    <Fragment>
      <div className="modal-header border-bottom-0 p-0 mb-4">
        <h5 className="page-subtitle">
          Where would you like to withdraw into?
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Fragment>
        {isLoading ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <Loader type="lg" variant="blue" />
          </div>
        ) : error ? (
          <div className="py-5 d-flex justify-content-center align-items-center flex-column">
            <h6 className="color-red font-weight-bold text-center">
              {errorHandler(error) ||
                "An error occured while getting your account information, please try again."}
            </h6>

            <button
              type="button"
              className="btn btn-sm advancly-white-btn  mt-3"
              onClick={() => refetch()}
              disabled={isFetching}
            >
              Retry {isFetching && <Loader />}
            </button>
          </div>
        ) : (
          <Fragment>
            <div
              className={`${styles.modalCard}  ${
                type === "saved" && styles.active
              }`}
              onClick={() => setType("saved")}
            >
              <div className={styles.radioButton}></div>
              <div>
                <h6 className="font-weight-bold">Saved Account</h6>
                <p>
                  {data?.bank_name} {data?.account_number}
                </p>
              </div>
            </div>

            {/* No need to add new account for now */}
            {/* <div
              className={`${styles.modalCard} ${
                type === "new" && styles.active
              }`}
              onClick={() => setType("new")}
            >
              <div className={styles.radioButton}></div>
              <div>
                <h6 className="font-weight-bold">New Account</h6>
                <p>Withdraw money into a different account</p>
              </div>
            </div> */}

            <div className="d-flex justify-content-between mt-5">
              <button
                type="button"
                className="btn btn-sm advancly-white-btn w-50 mr-2"
                onClick={() => {
                  setWithdrawalInfo({
                    ...withdrawalInfo,
                    currentStep: 1,
                  });
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-sm advancly-btn w-50 ml-2"
                disabled={!type || isError}
                onClick={() => {
                  if (type === "saved") {
                    setWithdrawalInfo({
                      ...withdrawalInfo,
                      currentStep: 4,
                      accountDetails: {
                        ...withdrawalInfo.accountDetails,
                        type,
                        bankId: data?.bank_id,
                        accountNumber: data?.account_number,
                        accountName: data?.account_name,
                      },
                    });
                  } else if (type === "new") {
                    setWithdrawalInfo({
                      ...withdrawalInfo,
                      currentStep: 3,
                    });
                  }
                }}
              >
                Continue
              </button>
            </div>
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
};
