import { useState, useRef, Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import { postData } from "./../../apis/apiMethods";
import { errorHandler } from "./../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CustomInputField from "./../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "./../CustomHTMLElements/CustomSelectDropdown";
import useBanks from "./../../hooks/custom-hooks/useBanks";
import PageLoader from "./../PageLoader/PageLoader.component";
import Loader from "../Loader/Loader.component";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "./../../redux/selectors/userSelector";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { useQueryClient } from "react-query";
import useValidateBankAccount from "./../../hooks/custom-hooks/useValidateBankAccount";
import {appInsights} from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  bank_account_number: yupValidators.genericRequiredNumber({
    message: "Please enter account number",
    max: 20,
  }),
  bank_id: yupValidators.genericRequired({
    message: "Please select your bank",
  }),
});

interface IUpdateBankInfoModal {
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const UpdateBankInfo: React.FC<IUpdateBankInfoModal> = ({
  setSuccess,
  setError,
}) => {
  const user = useTypedSelector(selectUser);
  const { setCurrentUser } = useActions();
  const [loading, setLoading] = useState(false);
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, isError, isLoading } = useBanks();

  const accountNumber = watch("bank_account_number");
  const bankId = watch("bank_id");
  const bankCode = data?.data?.find((bank: any) => {
    return Number(bank?.id) === Number(bankId);
  })?.code;

  useEffect(() => {
    setValue("bank_account_number", "");
    // eslint-disable-next-line
  }, [bankId]);

  const {
    data: accountName,
    isLoading: isAccountNameLoading,
    isError: isAccountNameError,
    error: accountNameError,
  } = useValidateBankAccount({
    bankCode,
    accountNumber,
  });

  if (isAccountNameError) {
    setError(errorHandler(accountNameError));
  }

  const onSubmit = async ({
    bank_id,
    bank_account_number,
  }: {
    bank_id: string;
    bank_account_number: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      bank_id: Number(bank_id),
      bank_account_number,
      bank_account_name: accountName,
    };

    try {
      await postData(apiEndpoints.UPDATE_USER_BANK_INFO, reqBody);
      queryClient.invalidateQueries("getFunder");
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({ ...user, activated });
      }

      reset();
      setSuccess("Bank information updated successfully.");
      setLoading(false);
      dismissModal();
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      dismissModal();
      appInsights.trackException({
        exception: error,
        properties: {fileName : UpdateBankInfo}
      })
    }
  };

  return (
    <div
      className="modal fade"
      id="updateBankInfoModal"
      tabIndex={-1}
      aria-labelledby="updateBankInfoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">New Bank Information</h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            {isLoading || isAccountNameLoading ? (
              <PageLoader />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomSelectDropdown
                  {...register("bank_id")}
                  label="Bank"
                  errors={errors.bank_id}
                  showRequiredIcon={true}
                >
                  <option value="">Choose Bank</option>
                  {data?.data?.map(
                    ({ name, id }: { name: string; id: number }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    )
                  )}
                </CustomSelectDropdown>
                <CustomInputField
                  type="number"
                  maxLength={20}
                  placeholder="Enter Account number"
                  label="Account Number"
                  errors={errors.bank_account_number}
                  {...register("bank_account_number")}
                />
                <CustomInputField
                  type="text"
                  maxLength={128}
                  placeholder="Account Name"
                  label="Account Name"
                  defaultValue={accountName}
                  value={accountName}
                  readOnly
                />
                <button
                  className="btn btn-sm advancly-btn mt-3 w-100"
                  disabled={
                    loading ||
                    isLoading ||
                    isError ||
                    isAccountNameLoading ||
                    !accountName ||
                    isAccountNameError
                  }
                >
                  Update Bank Information {loading && <Loader />}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateBankInfo;
