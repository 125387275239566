import { createSelector } from "reselect";
import { TRootState } from "../reducers";

const selectState = (state: TRootState) => state.investment;

// Output Selectors
export const selectInvestmentCurrentStep = createSelector(
  [selectState],
  (investment) => investment.currentStep
);
export const selectInvestmentType = createSelector(
  [selectState],
  (investment) => investment.investmentType
);
export const selectInvestmentSteps = createSelector(
  [selectState],
  (investment) => investment.steps
);
export const selectOnlineInvestmentCurrency = createSelector(
  [selectState],
  (investment) => investment.onlineInvestmentInfo.currency
);
export const selectOfflineInvestmentCurrency = createSelector(
  [selectState],
  (investment) => investment.offlineInvestmentInfo.currency
);


export const selectOnlineInvestment = createSelector(
  [selectState],
  (investment) => investment.onlineInvestmentInfo
);
export const selectOfflineInvestment = createSelector(
  [selectState],
  (investment) => investment.offlineInvestmentInfo
);
export const selectSelectedInvestment = createSelector(
  [selectState],
  (investment) => investment.selectedInvestment
);
export const selectInvestmentLocalCurrencyId = createSelector(
  [selectState],
  (investment) => investment.selectedInvestment.lcy_product_id
);
export const selectInvestmentUsdCurrencyId = createSelector(
  [selectState],
  (investment) => investment.selectedInvestment.usd_product_id
);
export const selectAgreementCheck= createSelector(
  [selectState],
  (investment) => investment.agreementCheck
);