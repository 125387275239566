const envBaseUrls = {
  staging: "https://advancly-api-master.staging.vggdev.com/api/v1",
  master: "https://advancly-api-master.test.vggdev.com/api/v1",
  sprint1: "https://advanclyapispnttest.azurewebsites.net/api/v1",
  sprint2: "https://advanclyapispnt2test.azurewebsites.net/api/v1",
};

const baseUrl =
  process.env.NODE_ENV === "development"
    ? envBaseUrls.sprint2
    : // @ts-ignore
      window.env.baseUrl;

const encyptionKey =
  process.env.NODE_ENV === "development"
    ? "this_is_meant_to_be_a_very_secretive_P@55w0rd01"
    : // @ts-ignore
      window.env.encryptionKey;

const paystackPublicKey =
  process.env.NODE_ENV === "development"
    ? "pk_test_6e6904d11efb3b40c7cad5611bcba13fb362f4ec"
    : // @ts-ignore
      window.env.paystackPublicKey;

const powerBiGroupId =
  process.env.NODE_ENV === "development"
    ? "968461ce-6158-4f31-a524-b4fac19706c5"
    : // @ts-ignore
      window.env.powerBiGroupId;
const powerBiReportId =
  process.env.NODE_ENV === "development"
    ? "eaa967b2-174d-41c8-bb67-3793e988e3ad"
    : // @ts-ignore
      window.env.powerBiReportId;
const powerBiDatasetId =
  process.env.NODE_ENV === "development"
    ? "f980293d-4545-40be-82eb-c5703f97c4aa"
    : // @ts-ignore
      window.env.powerBiDatasetId;
const useMock = false;

const documentCodes = {
  proofOfAddressIndividual: "PAI",
  proofOfAddressCorporate: "PAC",
  governmentIssuedIdentity: "GII",
  businessRegistrationDocument: "BRD",
};

const ADVANCLY_MOBILE_ONELINK = "http://onelink.to/u3b6c6";

export const getAppInsightKey = () => {
  // @ts-ignore
  switch (window.env.baseUrl) {
    //Sprint 1
    case "https://advancly-api-sprint.test.vggdev.com/api/v1":
      return "3b0f1eb2-8516-45ce-ae67-eb4cf86a4e8a";
    //Sprint 2
    case "https://advancly-api-sprint2.test.vggdev.com/api/v1":
      return "6154b933-4a1e-4389-9512-2d3f228d4d74";
    // Sprint 3
    case "https://advancly-api-sprint3.test.vggdev.com/api/v1":
      return "424210dd-c4a3-421c-9ba7-bd14602f43a3";
    // Master
    case "https://advancly-api-master.test.vggdev.com/api/v1":
      return "1988874b-476c-4ca7-ba91-697586ffe010";
    // Staging
    case "https://advancly-api-master.staging.vggdev.com/api/v1":
      return "a6b45ff5-83d1-4f64-b2aa-a6bb978c168e";
    //Prod
    case "https://api.advancly.com/api/v1":
      return "5b857ac5-f5e8-4daa-9279-34840af38f6a";
    // Using App Insight Master 2 for local development bcos 'window.env.baseUrl' returns undefined on local
    default:
      return "f1168912-bd16-408d-add6-3eea956501b3";
  }
};

const CONFIG = {
  // BASE_URL: "https://advanclyapispnttest.azurewebsites.net/api/v1",
  BASE_URL: baseUrl,
  ENCRYPTION_KEY: encyptionKey,
  PAYSTACK_PUBLIC_KEY: paystackPublicKey,
  POWERBI_GROUP_ID: powerBiGroupId,
  POWERBI_REPORT_ID: powerBiReportId,
  POWERBI_DATASET_ID: powerBiDatasetId,
  DOCUMENT_CODES: documentCodes,
  ADVANCLY_MOBILE_ONELINK,
  APPINSIGHTS_KEY: getAppInsightKey(),
  USE_MOCK: useMock,
};

export default CONFIG;
