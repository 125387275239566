import { useState, useEffect } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import useWorldCountries from "../../hooks/custom-hooks/useWorldCountries";
import useCountryStates from "../../hooks/custom-hooks/useCountryStates";
import PageLoader from "../PageLoader/PageLoader.component";
import useNextOfKinDetails from "../../hooks/custom-hooks/useNextOfKinDetails";
import countryCodeEmoji from "country-code-emoji";
import { useQueryClient } from "react-query";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUserId } from "../../redux/selectors/userSelector";
import {appInsights} from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  firstName: yupValidators.genericRequired({
    message: "Please enter your first name",
    min: 1,
    max: 30,
  }),
  lastName: yupValidators.genericRequired({
    message: "Please enter your last name",
    min: 1,
    max: 30,
  }),
  relationship: yupValidators.genericRequired({
    message: "Please choose your relationship with your next of kin",
  }),
  contactNumber: yupValidators.phoneNumber,
  address: yupValidators.genericRequired({
    message: "Please enter your address",
    min: 1,
    max: 100,
  }),
  city: yupValidators.genericRequired({
    message: "Please enter your city",
    min: 1,
    max: 30,
  }),
  country: yupValidators.genericRequired({
    message: "Please choose a country",
  }),
  state: yupValidators.genericRequired({
    message: "Please select your state",
    min: 1,
    max: 30,
  }),
  email: yupValidators.email,
});

interface IAccountInformation {}

const NextOfKinInformation: React.FC<IAccountInformation> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { data, error: nextOfKinsError, isLoading } = useNextOfKinDetails();
  const funderId = useTypedSelector(selectUserId);
  const queryClient = useQueryClient();

  const { data: countries, isLoading: isLoadingCountries } =
    useWorldCountries();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const country = watch("country");
  const resolveCountryInfo = countries?.find(
    ({ iso2 }: { iso2: string }) => iso2 === country
  );
  const { data: states, isLoading: isStatesLoading } = useCountryStates({
    countryId: resolveCountryInfo?.id,
  });

  useEffect(() => {
    if (data?.state && !isLoading) {
      setValue("state", data?.state);
    }
    // eslint-disable-next-line
  }, [data, states]);

  const onSubmit = async ({
    relationship,
    contactNumber,
    firstName,
    lastName,
    address,
    city,
    state,
    country,
    email,
  }: {
    relationship: string;
    contactNumber: string;
    firstName: string;
    lastName: string;
    address: string;
    state: string;
    city: string;
    country: string;
    email: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      first_name: firstName,
      phone_number: contactNumber,
      last_name: lastName,
      street: address,
      city,
      state,
      country,
      email,
      dial_code: resolveCountryInfo?.dialCode,
      relationhip_with_next_kin: relationship,
      funder_id: funderId,
    };

    try {
      await postData(apiEndpoints.UPDATE_NEXT_OF_KIN, reqBody);
      queryClient.invalidateQueries("getNextOfKinsDetails");
      setSuccess("Information updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : NextOfKinInformation}
      })
    }
  };
  return (
    <div>
      {isLoadingCountries || isLoading ? (
        <PageLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert message={error} />}
          {nextOfKinsError && <Alert message={errorHandler(nextOfKinsError)} />}
          {success && <Alert message={success} type="success" />}

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <CustomInputField
                type="text"
                maxLength={30}
                placeholder="First Name"
                label="First Name"
                {...register("firstName")}
                errors={errors.firstName}
                defaultValue={data?.first_name}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomInputField
                type="text"
                maxLength={30}
                placeholder="Last Name"
                label="Last Name"
                {...register("lastName")}
                errors={errors.lastName}
                defaultValue={data?.last_name}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomInputField
                type="email"
                placeholder="morty@ricknmorty.com"
                label="Email Address"
                {...register("email")}
                errors={errors.email}
                defaultValue={data?.email}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomSelectDropdown
                label="Country"
                {...register("country")}
                errors={errors.country}
                defaultValue={data?.country}
              >
                <option value="">Country</option>
                {countries?.map(
                  ({
                    name,
                    id,
                    iso2,
                  }: {
                    iso2: string;
                    name: string;
                    id: number;
                  }) => {
                    return (
                      <option value={iso2} key={id}>
                        {name}
                      </option>
                    );
                  }
                )}
              </CustomSelectDropdown>
            </div>
            <div className="col-12 col-md-6">
              <CustomInputField
                type="number"
                maxLength={20}
                {...register("contactNumber")}
                placeholder="08000000000"
                label="Contact Number"
                name="contactNumber"
                errors={errors.contactNumber || errors.country}
                defaultValue={data?.phone_number}
              >
                <select disabled>
                  <option value="">
                    {resolveCountryInfo?.iso2 &&
                      countryCodeEmoji(resolveCountryInfo?.iso2)}{" "}
                    {resolveCountryInfo?.dialCode}
                  </option>
                </select>
              </CustomInputField>
            </div>
            {/* <div className="col-12 col-md-6">
              <CustomSelectDropdown
                label="Relationship with Next of Kin"
                {...register("relationship")}
                errors={errors.relationship}
                defaultValue={data?.relationhip_with_next_kin}
              >
                <option value="">Relationship</option>
                <option value="parent">Parent</option>
                <option value="sibling">Sibling</option>
                <option value="spouse">Spouse</option>
                <option value="child">Child</option>
                <option value="others">Others</option>
              </CustomSelectDropdown>
            </div> */}
            <div className="col-12 col-md-6">
              <CustomInputField
                type="text"
                label="Relationship with Next of Kin"
                placeholder="e.g. Mother"
                maxLength={30}
                {...register("relationship")}
                errors={errors.relationship}
                defaultValue={data?.relationhip_with_next_kin}
              ></CustomInputField>
            </div>
            <div className="col-12 col-md-6">
              <CustomInputField
                type="text"
                maxLength={100}
                placeholder="e.g. 45, Adekunle Road"
                label="Address"
                {...register("address")}
                errors={errors.address}
                defaultValue={data?.street}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomInputField
                type="text"
                maxLength={30}
                placeholder="e.g. Ikeja"
                label="City"
                {...register("city")}
                errors={errors.city}
                defaultValue={data?.city}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomSelectDropdown
                label="State"
                {...register("state")}
                errors={errors.state}
                extraLabel={isStatesLoading ? "Loading..." : ""}
                defaultValue={data?.state}
              >
                <option value="">State</option>
                {states?.map(({ name, id }: { name: string; id: number }) => {
                  return (
                    <option value={name} key={id}>
                      {name}
                    </option>
                  );
                })}
              </CustomSelectDropdown>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-sm advancly-white-btn"
            disabled={loading || isStatesLoading || isLoadingCountries}
          >
            Update
            {loading && <Loader />}
          </button>
        </form>
      )}
    </div>
  );
};
export default NextOfKinInformation;
