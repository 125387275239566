import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import { ROUTES } from "./../../../../../helpers/routes";
import { useHistory } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../../../assets/img/coin-icon.svg";
import { postData } from "./../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import {
  selectOnlineInvestment,
  selectSelectedInvestment,
} from "./../../../../../redux/selectors/investmentSelector";
import { useTypedSelector } from "./../../../../../hooks/redux-hooks/useTypedSelector";
import Loader from "../../../../Loader/Loader.component";
import { useEffect } from "react";
import { errorHandler } from "./../../../../../helpers/errorHandler";
import { selectUser } from "./../../../../../redux/selectors/userSelector";
import { downloadPdfFile } from "../../../../../helpers/downloadPdfFile";
import Alert from "./../../../../Alert/Alert.component";
import {
  USD_CURRENCY_CODE,
  LOCAL_CURRENCY_CODE,
} from "../../../../../helpers/currencies";
import { add } from "date-fns";
import {appInsights} from "../../../../AppInsight/AppInsight";

interface IInvestmentModalStepThreeSuccess {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
  error: string;
  setSection: Dispatch<SetStateAction<"initiate" | "confirm" | "success">>;
}

const InvestmentModalStepThreeSuccess: React.FC<
  IInvestmentModalStepThreeSuccess
> = ({ closeModal, setError, error, setSection }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [portfolioId, setPortfolioId] = useState<null | number>(null);
  const history = useHistory();

  useEffect(() => {
    async function makeInvestment() {
      await logOnlineInvestment();
    }
    makeInvestment();

    // eslint-disable-next-line
  }, []);

  const investmentDetails = useTypedSelector(selectOnlineInvestment);
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const { funder_id, lcy_wallet_id, usd_wallet_id } =
    useTypedSelector(selectUser);

  const {
    currency,
    amount,
    duration,
    repaymentPlan,
    paymentMethod,
    paymentGatewayReference,
    reference,
    status,
    response,
  } = investmentDetails;

  const { investment_id } = selectedInvestment;

  const downloadSlip = async () => {
    setIsDownloading(true);
    try {
      await downloadPdfFile({
        name: "Advancly Term Sheet",
        url: `${apiEndpoints.DOWNLOAD_INVESTMENT_SLIP}/${portfolioId}`,
      });
      setIsDownloading(false);
      closeModal();
    } catch (error) {
      setIsDownloading(false);
      appInsights.trackException({
        exception: error,
        properties: {fileName : InvestmentModalStepThreeSuccess}
      })
    }
  };

  const logOnlineInvestment = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    const effective_date = new Date().toISOString();
    const maturity_date = add(new Date(), {
      days: duration?.days,
    });

    const reqBody = {
      funder_id,
      cba_wallet_account_id:
        currency === LOCAL_CURRENCY_CODE
          ? lcy_wallet_id
          : currency === USD_CURRENCY_CODE
          ? usd_wallet_id
          : null,
      investment_product_id: investment_id,
      cba_product_id: investment_id,
      cba_product_interest_id: investment_id,
      principal_amount: Number(amount),
      interest_rate: duration?.interest,
      //@ts-ignore
      interest_amount: Number((amount * duration.interest) / 100),
      effective_date,
      maturity_date,
      funding_channel: paymentMethod,
      currency,
      interest_repayment_type: repaymentPlan,
      payment_gateway_reference: paymentGatewayReference,
      internal_payment_reference: reference,
      status,
      gateway_response: response,
      base64_file_string: "",
      filename_with_extension: "",
      tenure: Number(duration?.days),
    };

    try {
      const res = await postData(apiEndpoints.MAKE_INVESTMENT, reqBody);
      setLoading(false);
      setSuccess(res.message);
      setPortfolioId(res?.funderInvestmentId);
      setTimeout(() => {
        closeModal();
      }, 20000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : InvestmentModalStepThreeSuccess}
      })
    }
  };
  return (
    <div>
      {success && <Alert message={success} type="success" />}
      <div className="d-flex text-center justify-content-center align-items-center flex-column py-5 px-3">
        {loading ? (
          <Loader type="lg" variant="blue" />
        ) : error ? (
          <Fragment>
            <h6 className="color-red font-weight-bold">
              {errorHandler(error) ||
                "An error occured while processing your investment, please try again."}
            </h6>

            <div className="d-flex justify-content-center mt-5 w-100">
              <button
                type="button"
                className="btn btn-sm advancly-red-btn mr-2 w-100"
                onClick={() => setSection("initiate")}
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-sm advancly-white-btn ml-2 w-100"
                onClick={logOnlineInvestment}
              >
                Retry
              </button>
            </div>
          </Fragment>
        ) : success ? (
          <Fragment>
            <div className="d-flex text-center justify-content-center align-items-center flex-column">
              <SuccessIcon />
              <h5 className="page-subtitle my-3">Transaction Complete</h5>
              {success}
            </div>
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-sm advancly-btn mb-3 w-100"
                onClick={() => {
                  closeModal();
                  history.push(
                    portfolioId
                      ? `${ROUTES.PORTFOLIO}/${portfolioId}`
                      : ROUTES.PORTFOLIO
                  );
                }}
                disabled={isDownloading}
              >
                View Investment
              </button>
              {portfolioId && (
                <button
                  type="button"
                  className="btn btn-sm advancly-white-btn w-100"
                  onClick={downloadSlip}
                  disabled={isDownloading || !portfolioId}
                >
                  Download Investment Slip
                </button>
              )}
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};
export default InvestmentModalStepThreeSuccess;
