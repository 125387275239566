import { BackButton } from "../../../components/BackButton/BackButton.component";
import useInvestments from "./../../../hooks/custom-hooks/useInvestments";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "./../../../helpers/errorHandler";
import { Fragment, useEffect } from "react";
import Loader from "../../../components/Loader/Loader.component";
import { InvestmentCard } from "./../../../components/InvestmentCard/InvestmentCard.component";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectUserCountry } from "../../../redux/selectors/userSelector";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function Investments() {
  const { data, isLoading, error } = useInvestments();
  const countryCode = useTypedSelector(selectUserCountry);

  let investments = data;

  useEffect(() => {
    appInsights.trackPageView({ name: "Investment.tsx", isLoggedIn: true });
  }, []);
  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="mb-3">
        <BackButton text={<div className="page-title">New Investment</div>} />
      </div>
      <Fragment>
        {isLoading ? (
          <div className="no-advancly-data">
            <Loader type="lg" variant="blue" />
            <div className="mt-3">Fetching available investments...</div>
          </div>
        ) : !investments?.length ? (
          <div className="no-advancly-data">
            <i className="ri-briefcase-4-fill ri-3x"></i>
            <div className="color-bg-blue py-2 font-weight-bold">
              There are no open investments at the moment, please check back at
              a later time.
            </div>
          </div>
        ) : (
          <Fragment>
            <p>
              These are the available investment opportunities at the moment.
            </p>
            <div className="three-grid-layout">
              {investments?.map((investment: any) => {
                return (
                  <InvestmentCard investment={investment} key={investment.id} />
                );
              })}
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
}
