import React, { Fragment, useEffect, useState } from "react";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import { attachClassNameToTag } from "./../../../helpers/attachClassNameToTag";
import InvestmentSummaryGridCard from "../../../components/InvestmentSummaryGridCard/InvestmentSummaryGridCard.component";
import formatMoney from "./../../../helpers/formatMoney";
import InvestmentMap from "../../../components/InvestmentMap/InvestmentMap.component";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import usePortfoilio from "./../../../hooks/custom-hooks/usePortfoilio";
import { match, useHistory, useRouteMatch } from "react-router-dom";
import Loader from "../../../components/Loader/Loader.component";
import { formatDateFull } from "../../../helpers/formatDate";
import { formatRepaymentType } from "../../../helpers/generalUtils";
import { downloadPdfFile } from "../../../helpers/downloadPdfFile";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import SwitchButton from "../../../components/SwitchButton/SwitchButton.component";
import RollOverModal from "../../../components/RollOver/RollOverModal";
import { Button, message } from "antd";
import { postData } from "../../../apis/apiMethods";
import { ROUTES } from "../../../helpers/routes";

interface IPortfolioDetailProps {}

export const PortfolioDetail: React.FC<IPortfolioDetailProps> = () => {
  const history = useHistory();
  const match: match<{ id: string }> = useRouteMatch();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState("");
  const [showRollOverModal, setShowRollOverModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, error, isLoading } = usePortfoilio({
    funderInvestmentId: Number(match.params.id),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "PortfolioDetail.tsx",
      isLoggedIn: true,
    });
  }, []);

  const downloadSlip = async () => {
    setIsDownloading(true);
    setDownloadError("");
    try {
      await downloadPdfFile({
        url: `${apiEndpoints.DOWNLOAD_INVESTMENT_SLIP}/${Number(
          match.params.id
        )}`,
      });
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      setDownloadError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "PortfolioDetail" },
      });
    }
  };

  const handleWithdraw = async () => {
    try {
      setLoading(true);
      const res = await postData(apiEndpoints.WITHDRAW_INVESTMENT, {
        investment_id: data.id,
      });
      message.success(res.message);
      history.push(ROUTES.PORTFOLIO);
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "PortfolioDetail.tsx" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      {downloadError && <Alert message={errorHandler(downloadError)} />}

      {isLoading || loading ? (
        <div className="no-advancly-data">
          <Loader type="lg" variant="blue" />
          {isLoading && !loading && (
            <div className="mt-3">Fetching investment information...</div>
          )}
        </div>
      ) : (
        <Fragment>
          <div className="title-align">
            <BackButton
              text={
                <Fragment>
                  <div className="page-title mr-3">
                    {data?.investment_product_name}
                  </div>
                  <small
                    className={attachClassNameToTag(data?.investment_status)}
                  >
                    {data?.investment_status}
                  </small>
                </Fragment>
              }
            />
            {data?.investment_status?.toLowerCase() === "matured" && (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center mr-5">
                  <span className="color-bg-blue mr-3">
                    Rollover Investment
                  </span>
                  <SwitchButton
                    onChange={(checkedState) =>
                      setShowRollOverModal(checkedState)
                    }
                    loading={false}
                    checkedState={data.is_roll_over}
                    disabled={data.is_roll_over}
                  />
                </div>
                <button
                  className="btn btn-sm advancly-white-btn"
                  onClick={() => handleWithdraw()}
                >
                  Withdraw
                </button>
              </div>
            )}
            {data?.investment_status.toLowerCase() === "active" && (
              <button
                className="btn btn-sm advancly-btn"
                disabled={isDownloading}
                onClick={downloadSlip}
              >
                Download Slip {isDownloading && <Loader />}
              </button>
            )}
          </div>
          {/* Investment Summary Cards  */}
          <div className="four-grid-layout">
            <InvestmentSummaryGridCard
              title="Amount Invested"
              value={formatMoney(data?.principal_amount, data?.currency)}
              loading={isLoading}
            />
            <InvestmentSummaryGridCard
              title="Tenor"
              value={data?.tenure ? `${data?.tenure} Days` : ""}
              loading={isLoading}
            />
            <InvestmentSummaryGridCard
              title="Interest Payable"
              value={formatMoney(data?.interest_amount, data?.currency)}
              loading={isLoading}
            />
            <InvestmentSummaryGridCard
              title="Monthly Interest Payable"
              value={formatMoney(
                data?.monthly_interest_repayment,
                data?.currency
              )}
              loading={isLoading}
            />
          </div>

          {/* Investment Details Section  */}
          <div className="card mt-4 p-0">
            <div className="card-header bg-lighter-grey font-weight-bold">
              Investment Details
            </div>
            <div className="four-grid-layout">
              <div className="p-3">
                <small className="color-grey">Start Date</small>
                <div className="font-weight-bold">
                  {formatDateFull(data?.effective_date)}
                </div>
              </div>
              <div className="p-3">
                <small className="color-grey">Maturity Date</small>
                <div className="font-weight-bold">
                  {formatDateFull(data?.maturity_date)}
                </div>
              </div>
              <div className="p-3">
                <small className="color-grey">Interest Repayment Type</small>
                <div className="font-weight-bold">
                  {formatRepaymentType(data?.interest_repayment_type)}
                </div>
              </div>
              <div className="p-3">
                <small className="color-grey">Reference Number</small>
                <div className="font-weight-bold">{data?.reference_number}</div>
              </div>
            </div>
          </div>

          {/* Portfolio Map Section  */}
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="page-subtitle my-4">Investment Allocation</h5>
          </div>
          <InvestmentMap />
          <RollOverModal
            open={showRollOverModal}
            onCancel={() => window.location.reload()}
            investment={data}
          />
        </Fragment>
      )}
    </div>
  );
};
