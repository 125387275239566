const FUNDER_ROOT_ROUTE = "/funder";

export const ROUTES = {
  LOGIN: "/",
  SIGN_UP: "/signup",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_VALIDATE_OTP: "/reset-password/validate-otp",
  RESET_PASSWORD_SET_PASSWORD: "/reset-password/set-password",
  FUNDER: FUNDER_ROOT_ROUTE,
  DASHBOARD: `${FUNDER_ROOT_ROUTE}/dashboard`,
  SECTORS: `${FUNDER_ROOT_ROUTE}/sectors`,
  PORTFOLIO: `${FUNDER_ROOT_ROUTE}/portfolios`,
  INVESTMENTS: `${FUNDER_ROOT_ROUTE}/investments`,
  WALLET: `${FUNDER_ROOT_ROUTE}/wallets`,
  PROFILE_SETTINGS: `${FUNDER_ROOT_ROUTE}/profile-settings`,
  PROFILE_SETTINGS_GOV_ISSUED_ID: `${FUNDER_ROOT_ROUTE}/profile-settings/upload-govt-id`,
  PROFILE_SETTINGS_BUSINESS_DOCUMENT: `${FUNDER_ROOT_ROUTE}/profile-settings/business-document`,
  PROFILE_SETTINGS_ADDRESS_PROOF_INDIVIDUAL: `${FUNDER_ROOT_ROUTE}/profile-settings/individual-proof-of-address`,
  PROFILE_SETTINGS_ADDRESS_PROOF_CORPORATE: `${FUNDER_ROOT_ROUTE}/profile-settings/corporate-proof-of-address`,
  USERS: `${FUNDER_ROOT_ROUTE}/users`,
  CONTACT_SUPPORT: `${FUNDER_ROOT_ROUTE}/contact`,
  TERMS_OF_USE: `${FUNDER_ROOT_ROUTE}/terms-of-use`,
  PRIVACY_POLICY: `${FUNDER_ROOT_ROUTE}/privacy-policy`,
  MOBILE_TERMS_OF_USE: `/terms-and-condition`,
  MOBILE_PRIVACY_POLICY: `/privacy`,
  MOBILE: `/mobile`
};
