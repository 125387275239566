import React, { useRef, useState, Fragment } from "react";
import styles from "./FilePicker.module.scss";
import { convertToBase64 } from "../../helpers/base64";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Alert from "../Alert/Alert.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader.component";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUserId } from "../../redux/selectors/userSelector";
import { useQueryClient } from "react-query";
import {appInsights} from "../AppInsight/AppInsight";

interface FilePickerProps {
  DROPDOWN_OPTIONS: Array<{ value: string; optionText: string }>;
  document_type_code: string;
}

interface IFilePickerOptions {
  filename_with_extension: string;
  base64_file_string: string;
  document_type: string;
}

const schema = yup.object().shape({
  filename_with_extension: yupValidators.genericRequired({
    message: "Please choose a file",
  }),
  base64_file_string: yupValidators.genericRequired({
    message: "Please choose a file",
  }),
  document_type: yupValidators.genericRequired({
    message: "Please choose your document type",
  }),
});

const acceptedImageTypes =
  "image/jpg, image/jpeg, image/png, image/JPG, image/JPEG, image/PNG, application/pdf";

export const FilePicker: React.FC<FilePickerProps> = ({
  DROPDOWN_OPTIONS,
  document_type_code,
}) => {
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();

  const funderId = useTypedSelector(selectUserId);
  const queryClient = useQueryClient();

  const fileRef = useRef<null | HTMLInputElement>(null);

  const onFileChange = async (files: FileList | null) => {
    const file = files && files[0];
    if (!file) return;
    setFileError("");

    const fileName = file.name;
    const isValidFile =
      fileName?.toLowerCase().endsWith(".jpeg") ||
      fileName?.toLowerCase().endsWith(".jpg") ||
      fileName?.toLowerCase().endsWith(".png") ||
      fileName?.toLowerCase().endsWith(".pdf");

    if (!isValidFile) {
      setFileError("Invalid File type.");
      setValue("filename_with_extension", "");
      setValue("base64_file_string", "");
      return;
    }
    const fileBase64 = await convertToBase64(file);

    //@ts-ignore
    fileRef.current.value = "";
    setValue("filename_with_extension", fileName);
    setValue("base64_file_string", fileBase64);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const filename_with_extension = watch("filename_with_extension");

  const onSubmit = async ({
    filename_with_extension,
    base64_file_string,
    document_type,
  }: IFilePickerOptions) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      funder_id: funderId,
      funder_documents: [
        {
          document_type_code,
          type: document_type,
          base64_file_string: base64_file_string.split("base64,")[1],
          filename_with_extension,
        },
      ],
    };

    try {
      await postData(apiEndpoints.FUNDER_KYC_DOCUMENTS_UPLOAD, reqBody);
      setSuccess(
        `Your document has been uploaded successfully. You will be contacted via your email when an action has been taken on your document`
      );
      queryClient.invalidateQueries(["getFunderKycDocuments", { funderId }]);
      setLoading(false);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : FilePicker}
      })
    }
  };

  return (
    <div className={styles.pickerArea}>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className={styles.pickerCardArea}>
        <div className="col-12 px-0">
          <CustomSelectDropdown
            label="Document Type"
            {...register("document_type")}
            errors={errors.document_type}
          >
            <option value="">Select</option>
            {DROPDOWN_OPTIONS.map(({ optionText, value }) => (
              <option value={value} key={value}>
                {optionText}
              </option>
            ))}
          </CustomSelectDropdown>
        </div>
        <div
          className={styles.pickerCard}
          onClick={() => fileRef.current?.click()}
          onDrop={(e) => {
            e.preventDefault();
            onFileChange(e.dataTransfer.files);
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <div>
            <i className="ri-file-3-fill mb-3"></i>
          </div>
          <div>Browse files to upload an image</div>
        </div>
        {/* <button className="btn btn-md advancly-btn w-100 mt-5">
          Take a picture
        </button> */}

        {fileError ? (
          <ErrorHandler errors={{ message: fileError }} />
        ) : errors?.filename_with_extension && !filename_with_extension ? (
          <ErrorHandler errors={errors.filename_with_extension} />
        ) : (
          <Fragment>
            {filename_with_extension && (
              <div className="d-flex align-items-center mt-4">
                <i className="ri-attachment-2"></i>{" "}
                <small className="ml-2">{filename_with_extension}</small>
              </div>
            )}
          </Fragment>
        )}

        <button
          className="btn btn-md advancly-btn w-100 mt-5"
          onClick={handleSubmit(onSubmit)}
          disabled={loading || fileError?.length ? true : false}
        >
          Submit {loading && <Loader />}
        </button>
      </div>
      <input
        type="file"
        ref={fileRef}
        accept={acceptedImageTypes}
        className="d-none"
        onChange={(e) => {
          onFileChange(e.target.files);
        }}
      />
    </div>
  );
};
