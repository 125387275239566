import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import styles from "./Sidebar.module.scss";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUserType } from "./../../redux/selectors/userSelector";
import { TFunderIdTypes } from "../../interfaces/user";

const Sidebar = () => {
  const funderType: TFunderIdTypes = useTypedSelector(selectUserType);
  const match = useRouteMatch();
  return (
    <React.Fragment>
      <ul className={styles.sidebarElements}>
        {/* Dashboard */}
        <li>
          <NavLink
            className={`${styles.navLink} ${match.isExact && styles.selected}`}
            activeClassName={styles.selected}
            to={ROUTES.DASHBOARD}
          >
            <i className="ri-dashboard-fill"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>

        {/* Portfolio  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.PORTFOLIO}
          >
            <i className="ri-briefcase-4-fill"></i>
            <span>Portfolio</span>
          </NavLink>
        </li>

        {/* Sectors */}
        {/* <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.SECTORS}
          >
            <SectorsIcon />
            <span>Sectors</span>
          </NavLink>
        </li> */}

        {/* Investment */}
        {/* <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.INVESTMENTS}
          >
            <InvestmentsIcon />
            <span>Investments</span>
          </NavLink>
        </li> */}

        {/* Wallet */}

        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.WALLET}
          >
            <i className="ri-wallet-3-fill"></i>
            <span>Wallet</span>
          </NavLink>
        </li>

        <div className={styles.sidebarLine}></div>

        {funderType === 2 && (
          <li>
            <NavLink
              className={styles.navLink}
              to={ROUTES.USERS}
              activeClassName={styles.selected}
            >
              <i className="ri-user-6-fill"></i>
              <span>User Management</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.PROFILE_SETTINGS}
            activeClassName={styles.selected}
          >
            <i className="ri-settings-3-fill"></i>
            <span>Profile Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.CONTACT_SUPPORT}
            activeClassName={styles.selected}
          >
            <i className="ri-customer-service-2-fill"></i>
            <span>Contact Support</span>
          </NavLink>
        </li>
      </ul>
      <ul className={styles.sidebarElements}>
        <div className={styles.bottomSection}>
          <div className={`${styles.sidebarLine} ${styles.fullWidth}`}></div>
          {/* Footer Section */}
          <li>
            <NavLink
              className={styles.navLink}
              to={ROUTES.TERMS_OF_USE}
              activeClassName={styles.selected}
            >
              <i className="ri-file-list-line"></i>
              <span>Terms of Use</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.navLink}
              to={ROUTES.PRIVACY_POLICY}
              activeClassName={styles.selected}
            >
              <i className="ri-file-list-line"></i>
              <span>Privacy Policy</span>
            </NavLink>
          </li>
        </div>
      </ul>
    </React.Fragment>
  );
};
export default Sidebar;
