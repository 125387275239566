import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import {
  TInvestmentStep,
  TInvestment,
  TInvestmentType,
  TOnlineInvestmentInfo,
  TSelectedInvestment,
  TOfflineInvestmentInfo,
} from "../../interfaces/investment";

export const initialState: {
  currentStep: TInvestmentStep;
  steps: TInvestmentStep[];
  investmentType: TInvestmentType | "";
  agreementCheck: boolean;
  onlineInvestmentInfo: TOnlineInvestmentInfo;
  offlineInvestmentInfo: TOfflineInvestmentInfo;
  selectedInvestment: TSelectedInvestment;
} = {
  currentStep: 1,
  steps: [1, 2, 3, 4],
  investmentType: "",
  agreementCheck: false,
  onlineInvestmentInfo: {
    currency: "",
    amount: "",
    duration: {
      days: 0,
      interest: 0,
    },
    repaymentPlan: "",
    paymentMethod: "",
    paymentGatewayReference: "",
    advanclyTranRef: "",
    status: "",
    response: "",
    reference: "",
    numberOfDaysInYear: "",
  },
  offlineInvestmentInfo: {
    currency: "",
    amount: "",
    duration: {
      days: 0,
      interest: 0,
    },
    repaymentPlan: "",
    paymentDate: "",
    transactionReceiptName: "",
    transactionReceiptBase64: "",
    numberOfDaysInYear: "",
  },
  selectedInvestment: {
    name: "",
    investment_id: null,
    interest_ranges: [],
    usd_product_id: 0,
    lcy_product_id: 0,
    period_type: "",
    lcy_locked: false,
    usd_locked: false,
    numberOfDaysInYear: "",
  },
};

export const investmentReducer = produce(
  (state: TInvestment = initialState, action: TAction): TInvestment => {
    switch (action.type) {
      case actionType.SET_INVESTMENT_TYPE:
        state.investmentType = action.payload;
        return state;
      case actionType.SET_INVESTMENT_STEP:
        state.currentStep = action.payload;
        return state;
      case actionType.SET_INVESTMENT_AGREEMENT_CHECK:
        state.agreementCheck = action.payload;
        return state;
      case actionType.SET_ONLINE_INVESTMENT_INFO:
        state.onlineInvestmentInfo = {
          ...state.onlineInvestmentInfo,
          ...action.payload,
        };
        return state;
      case actionType.SET_OFFLINE_INVESTMENT_INFO:
        state.offlineInvestmentInfo = {
          ...state.offlineInvestmentInfo,
          ...action.payload,
        };
        return state;
      case actionType.SELECT_INVESTMENT:
        state.selectedInvestment = action.payload;
        return state;
      case actionType.RESET_INVESTMENT:
        return initialState;
      default:
        return state;
    }
  }
);
