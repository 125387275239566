import { Fragment, useEffect, useState } from "react";
import BusinessInformation from "./../../../components/BusinessInformation/BusinessInformation.component";
import AccountInformation from "./../../../components/AccountInformation/AccountInformation.component";
import ChangePassword from "./../../../components/ChangePassword/ChangePassword.component";
import BankingInformation from "./../../../components/BankingInformation/BankingInformation.component";
import useFunder from "./../../../hooks/custom-hooks/useFunder";
import PageLoader from "../../../components/PageLoader/PageLoader.component";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "./../../../helpers/errorHandler";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import {
  selectShowBalance,
  selectUser,
  selectUserType,
} from "./../../../redux/selectors/userSelector";
import BusinessKYC from "../../../components/BusinessInformation/BusinessKYC.component";
import IndividualKYC from "./../../../components/IndividualKYC/IndividualKYC.component";
import { TFunderIdTypes } from "../../../interfaces/user";
import SetPinModal from "../../../components/Security/SetPin/SetPinModal.component";
import useSavedSecurityQuestion from "./../../../hooks/custom-hooks/useSavedSecurityQuestion";
import Loader from "../../../components/Loader/Loader.component";
import ResetPinModal from "./../../../components/Security/ResetPin/ResetPinModal.component";
import AddressInformation from "../../../components/AddressInformation/AddressInformation.component";
import NextOfKinInformation from "../../../components/NextOfKinInformation/NextOfKinInformation.component";
import IdentityInformationIndividual from "../../../components/IdentityInformation/IdentityInformationIndividual.component";
import IdentityInformationCorporate from "../../../components/IdentityInformation/IdentityInformationCorporate.component";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import SwitchButton from "../../../components/SwitchButton/SwitchButton.component";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useActions } from "../../../hooks/redux-hooks/useActions";

interface IProfileSettingsLanding {
  activeTab:
    | "profile"
    | "business"
    | "banking"
    | "security"
    | "identity"
    | "nextOfKin";
}

const ProfileSettingsLanding: React.FC<IProfileSettingsLanding> = ({
  activeTab,
}) => {
  const funderType: TFunderIdTypes = useTypedSelector(selectUserType);
  const { setShowBalance } = useActions();
  const showBalance = useTypedSelector(selectShowBalance);

  useEffect(() => {
    console.log({ user: showBalance });
  }, []);

  const { data, isLoading, error } = useFunder();

  const {
    data: savedSecurityQuestion,
    isLoading: isLoadingSecurityQuestion,
    error: securityQuestionError,
  } = useSavedSecurityQuestion();

  useEffect(() => {
    appInsights.trackPageView({
      name: "ProfileSettingsLanding.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <Fragment>
      {error && <Alert message={errorHandler(error)} />}

      <div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Fragment>
            {(() => {
              switch (activeTab) {
                case "profile":
                  return (
                    <div className="align-unique-page">
                      <AccountInformation funder={data} isLoading={isLoading} />
                      <hr />
                      <AddressInformation funder={data} />
                    </div>
                  );
                case "business":
                  return (
                    <div className="align-unique-page">
                      <BusinessInformation funder={data} />
                      <hr />
                      <BusinessKYC funder={data} />
                    </div>
                  );
                case "banking":
                  return (
                    <Fragment>
                      <BankingInformation funder={data} />
                      <hr />
                      {funderType === 1 && (
                        <Fragment>
                          <IndividualKYC funder={data} />
                          <hr />
                        </Fragment>
                      )}
                    </Fragment>
                  );
                case "identity":
                  return (
                    <Fragment>
                      {funderType === 1 ? (
                        <IdentityInformationIndividual funder={data} />
                      ) : (
                        <IdentityInformationCorporate funder={data} />
                      )}
                    </Fragment>
                  );
                case "nextOfKin":
                  return (
                    <div className="align-unique-page">
                      <NextOfKinInformation />
                    </div>
                  );
                case "security":
                  return (
                    <div className="align-unique-page">
                      <ChangePassword />
                      <hr />
                      <h5 className="page-subtitle">Transaction PIN</h5>
                      {isLoadingSecurityQuestion ? (
                        <div className="d-flex justify-content-center align-items-center w-100 p-3">
                          <Loader />
                        </div>
                      ) : savedSecurityQuestion && !securityQuestionError ? (
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm advancly-white-btn"
                            data-toggle="modal"
                            data-target="#resetPinModal"
                            disabled={securityQuestionError ? true : false}
                          >
                            Reset PIN
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm advancly-white-btn"
                            data-toggle="modal"
                            data-target="#setPinModal"
                          >
                            Set PIN
                          </button>
                        </div>
                      )}
                      {/*    <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="page-subtitle">Show Wallet Balances</h5>
                        <SwitchButton
                          onChange={(checkedState) =>
                            setShowBalance(checkedState)
                          }
                          loading={false}
                          checkedState={showBalance}
                        />
                      </div>*/}
                      <hr />
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </Fragment>
        )}
      </div>
      <SetPinModal />
      <ResetPinModal />
    </Fragment>
  );
};

export default ProfileSettingsLanding;
