import React, { Fragment, useEffect, useState } from "react";
import styles from "./InvestmentDetails.module.scss";
import { BackButton } from "../../../components/BackButton/BackButton.component";
import InvestmentModal from "./../../../components/InvestmentModal/InvestmentModal.component";
import ToggleTab from "../../../components/ToggleTab/ToggleTab.component";
import { TCurrencies } from "../../../interfaces/currencies";
import {
  CURRENCIES,
  getCurrencyCodeByName,
} from "./../../../helpers/currencies";
import useInvestment from "../../../hooks/custom-hooks/useInvestment";
import { match, useRouteMatch } from "react-router-dom";
import { formatDateFull } from "./../../../helpers/formatDate";
import { resolveCurrencyNameBadges } from "./../../../helpers/resolveBadges";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "./../../../helpers/errorHandler";
import Loader from "../../../components/Loader/Loader.component";
import { useActions } from "./../../../hooks/redux-hooks/useActions";
import { InvestmentTermSheet } from "./../../../components/InvestmentTermSheet/InvestmentTermSheet.component";
import { downloadPdfFile } from "../../../helpers/downloadPdfFile";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import useInvestmentTermSheet from "./../../../hooks/custom-hooks/useInvestmentTermSheet";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectUserWallets } from "../../../redux/selectors/userSelector";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function InvestmentDetails() {
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const [activeTab, setActiveTab] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState("");
  const { selectInvestment, resetInvestment } = useActions();
  const match: match<{ id: string }> = useRouteMatch();

  const { data, error, isLoading, isError } = useInvestment({
    investmentId: Number(match.params.id),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestmentDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  const downloadtermSheet = async () => {
    const coreBankingId =
      data?.investment_product_details[0]?.core_banking_product_id;

    if (!coreBankingId) return;

    setIsDownloading(true);
    setDownloadError("");

    try {
      await downloadPdfFile({
        name: "Advest Term Sheet",
        url: `${apiEndpoints.DOWNLOAD_TERM_SHEET}/${coreBankingId}/tenantId/default`,
      });
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      setDownloadError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "InvestmentDetails" },
      });
    }
  };

  const badges = resolveCurrencyNameBadges([
    {
      code: data?.lcy_currency_code,
      value: data?.lcy_product?.id,
      walletId: lcy_wallet_id,
    },
    {
      code: data?.usd_currency_code,
      value: data?.usd_product?.id,
      walletId: usd_wallet_id,
    },
  ]);

  const isLockedAccount = () => {
    // If investment has both local and ud currency, it is locked only when both are locked
    if (data?.usd_product?.id && data?.lcy_product?.id) {
      return data?.lcy_locked && data?.usd_locked;
    } else if (data?.usd_product?.id && !data?.lcy_product?.id) {
      return data?.usd_locked;
    } else if (!data?.usd_product?.id && data?.lcy_product?.id) {
      return data?.lcy_locked;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (data) {
      setActiveTab(data.investment_product_details[0].currency_code);
    }
    // eslint-disable-next-line
  }, [data]);

  const resolveDataToMap = () => {
    return data?.investment_product_details[1]?.currency_code === "USD" &&
      activeTab === "USD"
      ? data?.investment_product_details[1]
      : data?.investment_product_details[0];
  };
  const dataToMap = resolveDataToMap() || {};

  const termSheetDetails = useInvestmentTermSheet({
    investmentId: dataToMap?.id,
  });

  const investmentTenor =
    termSheetDetails?.data?.interest_ranges[0]?.interest_rates?.map(
      ({
        duration,
        period_type,
      }: {
        duration: number;
        period_type: string;
      }) => {
        return `${duration} ${period_type}`;
      }
    );

  const disabled =
    !data?.id ||
    isError ||
    termSheetDetails.isLoading ||
    termSheetDetails.isError ||
    isLockedAccount();

  const getUSDProductId = () => {
    const usdProduct = data?.investment_product_details.find(
      (value: { currency_code: string }) => value.currency_code === "USD"
    );
    if (usdProduct) {
      return usdProduct.id;
    } else {
      return null;
    }
  };

  const getLocalCurrencyProductId = () => {
    const localCurrencyProduct = data?.investment_product_details.find(
      (value: { currency_code: string }) => value.currency_code !== "USD"
    );
    if (localCurrencyProduct) {
      return localCurrencyProduct.id;
    } else {
      return null;
    }
  };

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      {downloadError && <Alert message={errorHandler(downloadError)} />}
      <BackButton text="Back" />
      {isLoading ? (
        <div className="no-advancly-data">
          <Loader type="lg" variant="blue" />
          <div className="mt-3 text-center">
            Fetching investment information...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
            <div className="page-title">{data?.name}</div>
            <button
              className={`btn btn-sm ${
                isLockedAccount() ? "advancly-red-btn" : "advancly-btn"
              }  mr-2`}
              data-toggle="modal"
              data-target="#investmentModal"
              disabled={disabled}
              onClick={() => {
                resetInvestment();
                const interest_ranges = termSheetDetails?.data?.interest_ranges;
                return selectInvestment({
                  usd_product_id: getUSDProductId(),
                  lcy_product_id: getLocalCurrencyProductId(),
                  name: data?.name,
                  investment_id: data?.id,
                  interest_ranges,
                  period_type:
                    interest_ranges &&
                    interest_ranges[0]?.interest_rates[0]?.period_type,
                  lcy_locked: data?.lcy_locked,
                  usd_locked: data?.usd_locked,
                  numberOfDaysInYear:
                    termSheetDetails?.data?.number_of_days_in_year,
                  currencyCode: activeTab,
                  investment_product_details_id:
                    data?.investment_product_details[0].id,
                });
              }}
            >
              {isLockedAccount() ? "Sold Out" : "Invest Now"}
            </button>
          </div>

          <div className={styles.detailsGrid}>
            {/* Left hand side  */}
            <div>
              <div className="d-flex">
                {data.investment_product_details?.map(
                  (item: { currency_code: string }, i: number) => (
                    <div
                      className="badge bg-lighter-grey text-uppercase mr-3"
                      key={i}
                    >
                      {item.currency_code} FUND
                    </div>
                  )
                )}
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">About this Fund</h5>
                <p>{data?.description}</p>
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">Investment Objective</h5>
                <p>{data?.objective}</p>
              </div>
              <div className="mt-4">
                <h5 className="page-subtitle">Returns</h5>
              </div>

              <div className="mt-4">
                <div className="alignToggleTabItems mb-3">
                  {data.investment_product_details?.map(
                    (item: { currency_code: string }, i: number) => (
                      <ToggleTab
                        text={`${item.currency_code} Investment`}
                        id={item.currency_code}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        key={i}
                      />
                    )
                  )}
                </div>
              </div>
              {/* Term Sheet Table  */}
              <InvestmentTermSheet termSheetDetails={termSheetDetails} />
            </div>
            {/* Right Hand Side  */}
            <div>
              <div className="card p-3">
                <h6 className="font-weight-bold mb-3">
                  Some companies we Invest in:
                </h6>
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Issuer</div>
                  <div>{data?.issuers}</div>
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Target Investors</div>
                  <div>{data?.target_investors}</div>
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Tenor</div>
                  {termSheetDetails.isLoading ? (
                    <Loader />
                  ) : (
                    <Fragment>
                      {investmentTenor?.length && (
                        <div>{investmentTenor?.join(", ")}</div>
                      )}
                    </Fragment>
                  )}
                </div>
                <hr />
                <div className={styles.fancyPoint}>
                  <div className="font-weight-bold">Subscription Period</div>
                  <div>
                    {formatDateFull(data?.start_date)} to{" "}
                    {formatDateFull(data?.end_date)}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h6 className="font-weight-bold">
                  Want to learn more about this Fund?
                </h6>
                <div
                  className={`color-blue text-underline cursor-pointer ${
                    (isDownloading || disabled) && "cursor-disabled"
                  }`}
                  onClick={downloadtermSheet}
                >
                  Download Term Sheet {isDownloading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <InvestmentModal />
    </div>
  );
}
