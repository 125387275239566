import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import Loader from "../../components/Loader/Loader.component";
import { ROUTES } from "../../helpers/routes";
import Alert from "./../../components/Alert/Alert.component";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email address")
    .min(5, "Email must have at least 5 characters"),
});

interface IStepOneProps {}

const StepOne: React.FC<IStepOneProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const history = useHistory();

  const { setResetPasswordInfo } = useActions();

  useEffect(() => {
    appInsights.trackPageView({
      name: "ResetPasswordStepOne",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }: { email: string }) => {
    setLoading(true);
    setError(null);
    const reqBody = {
      reset_step: "first_step",
      email,
    };
    try {
      const response = await postData(apiEndpoints.RESET_PASSWORD, reqBody);
      setResetPasswordInfo({
        email,
        stateToken: response.state_token,
      });
      setLoading(false);
      history.push(ROUTES.RESET_PASSWORD_VALIDATE_OTP);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : 'ResetPasswordStepOne'}
      })
    }
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      {error && <Alert message={error} />}
      <div className="mb-5">
        <h3 className="h3 font-weight-bold mb-3 color-dark-blue">
          Forgot Password
        </h3>
        <p>Enter your email address to reset password.</p>
      </div>

      <CustomInputField
        type="email"
        maxLength={128}
        {...register("email")}
        name="email"
        placeholder="morty@ricknmorty.com"
        label="Email address"
        errors={errors.email}
      />
      <div className="d-flex justify-content-between align-items-center mt-5 flex-wrap">
        <p className="d-flex flex-wrap">
          <Link className="color-blue" to={ROUTES.LOGIN}>
            Back to log in
          </Link>
        </p>
        <button
          type="submit"
          value="submit"
          className="btn advancly-btn btn-sm"
          disabled={loading}
        >
          Continue
          {loading && <Loader />}
        </button>
      </div>
    </form>
  );
};

export default StepOne;
