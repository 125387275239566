import React, { useEffect, useState } from "react";
import { Button, Divider, message, Modal } from "antd";
import styles from "../InvestmentModal/InvestmentLanding/InvestmentLanding.module.scss";
import stylesCard from "../InvestmentModal/OnlineInvestment/InvestmentModalStepTwo/InvestmentModalStepTwo.module.scss";
import { ReactComponent as SuccessIcon } from "../../assets/img/done.svg";
import { TRepayment_Types, TRollOverType } from "../../interfaces/investment";
import { InvestmentRangeCard } from "../InvestmentRangeCard/InvestmentRangeCard.component";
import formatMoney from "../../helpers/formatMoney";
import { appInsights } from "../AppInsight/AppInsight";
import useInterestCalculation from "../../hooks/custom-hooks/useInterestCalculation";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectSelectedInvestment } from "../../redux/selectors/investmentSelector";
import useInvestmentTermSheet from "../../hooks/custom-hooks/useInvestmentTermSheet";
import Loader from "../Loader/Loader.component";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { add } from "date-fns";
import { getAdvanclyTranRef } from "../../helpers/generalUtils";

type RollOverModalProps = {
  open: boolean;
  onCancel: () => void;
  investment: {
    cba_product_id: number;
    investment_product_details_id: number;
    currency: string;
    id: number;
    principal_amount: number;
  };
};
const RollOverModal = ({ open, onCancel, investment }: RollOverModalProps) => {
  const [type, setType] = useState<TRollOverType>("");
  const [loading, setLoading] = useState(false);
  const [activeModal, setActiveModal] = useState<
    "principalOrInterest" | "tenure"
  >("principalOrInterest");
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const [error, setError] = useState("");
  const [extraInfo, setExtraInfo] = useState<{
    monthlyInterestRepayment: string;
    estimatedReturns: string;
  }>({
    monthlyInterestRepayment: "",
    estimatedReturns: "",
  });
  const [rollOverInfo, setRollOverInfo] = useState({
    duration: {
      days: 0,
      interest: 0,
    },
    repaymentPlan: "",
    numberOfDaysInYear: 0,
  });
  const [success, setSuccess] = useState(false);

  const {
    cba_product_id,
    principal_amount,
    currency,
    investment_product_details_id,
  } = investment;

  useEffect(() => {
    appInsights.trackPageView({
      name: "RollOverModal.tsx",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    if (rollOverInfo?.repaymentPlan === "") {
      message.error("Select a repayment plan");
      return;
    }
    if (rollOverInfo?.duration?.days < 1) {
      message.error("Select investment duration");
      return;
    }
    const reqBody = {
      investment_id: investment.id,
      roll_over_type: type,
      tenure: rollOverInfo.duration.days,
      internal_payment_reference: getAdvanclyTranRef(),
      interest_repayment_type: rollOverInfo.repaymentPlan,
    };
    try {
      setLoading(true);
      const res = await postData(apiEndpoints.ROLL_OVER_INVESTMENT, reqBody);
      setSuccess(true);
    } catch (error) {
      setError(errorHandler(error));
      message.error(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "RollOverModal.tsx" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRollOverInfo({
      ...rollOverInfo,
      numberOfDaysInYear: Number(selectedInvestment?.numberOfDaysInYear),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestment]);

  const { data, isError, isLoading } = useInterestCalculation({
    principalAmount: Number(principal_amount),
    interestRate: rollOverInfo?.duration?.interest,
    tenure: rollOverInfo?.duration?.days,
    repaymentPlan: rollOverInfo.repaymentPlan,
    numberOfDaysInYear: rollOverInfo?.numberOfDaysInYear,
  });

  useEffect(() => {
    if (data?.maturity_amount && data?.monthly_interest_amount) {
      setExtraInfo({
        estimatedReturns: data?.maturity_amount,
        monthlyInterestRepayment: data?.monthly_interest_amount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.maturity_amount, data?.monthly_interest_amount]);

  const termSheetDetails = useInvestmentTermSheet({
    investmentId: investment_product_details_id,
  });

  const interest_ranges = termSheetDetails?.data?.interest_ranges;

  const filteredInvestmentRange: any = interest_ranges?.find(
    (range: any) =>
      range.lower_amount <= Number(principal_amount) &&
      range.upper_amount >= Number(principal_amount)
  );

  const onChangeRepaymentFrequency = (plan: TRepayment_Types) => {
    setRollOverInfo({
      ...rollOverInfo,
      repaymentPlan: plan,
    });
  };

  useEffect(() => {
    setActiveModal("principalOrInterest");
    setType("");
    setExtraInfo({
      monthlyInterestRepayment: "",
      estimatedReturns: "",
    });
    setRollOverInfo({
      duration: {
        days: 0,
        interest: 0,
      },
      repaymentPlan: "",
      numberOfDaysInYear: 0,
    });
    setSuccess(false);
  }, [open]);

  const RollOverPrincipalOrInterest = () => {
    return (
      <>
        <h5 className="page-subtitle">How would you like to Invest?</h5>
        <br />
        <div
          className={`${styles.modalCard} ${
            type?.toLowerCase() === "principal" && styles.active
          }`}
          onClick={() => setType("Principal")}
        >
          <div className={styles.radioButton}></div>
          <div>
            <h6 className="font-weight-bold">Principal</h6>
            <p>
              Roll over the original amount you invested. This does not include
              interest earned.
            </p>
          </div>
        </div>
        <br />
        <div
          className={`${styles.modalCard} ${
            type?.toLowerCase() === "maturityamount" && styles.active
          }`}
          onClick={() => setType("MaturityAmount")}
        >
          <div className={styles.radioButton}></div>
          <div>
            <h6 className="font-weight-bold">Maturity Amount</h6>
            <p>
              Roll over the principal and profit you earned on your investment.
            </p>
          </div>
        </div>
        <button
          className="btn advancly-btn btn-sm w-100 mt-3"
          onClick={() => {
            setActiveModal("tenure");
          }}
          disabled={!type}
        >
          Continue
        </button>
      </>
    );
  };

  const Tenure = () => {
    return (
      <>
        <h5 className="page-subtitle">Roll Over</h5>
        <Divider />
        <form onSubmit={onSubmit}>
          <div>
            <p className="form-label">How long do you want to rollover for?</p>
            <div className={stylesCard.alignStepTwoBoxes}>
              {filteredInvestmentRange?.interest_rates?.map(
                (investment: any) => {
                  const { duration: days, rate, period_type } = investment;
                  return (
                    <InvestmentRangeCard
                      key={days}
                      duration={rollOverInfo?.duration}
                      isError={isError}
                      isLoading={isLoading}
                      repaymentPlan={
                        rollOverInfo?.repaymentPlan
                          ? rollOverInfo?.repaymentPlan
                          : ""
                      }
                      days={days}
                      onClick={() =>
                        setRollOverInfo({
                          ...rollOverInfo,
                          duration: {
                            days: days,
                            interest: rate,
                          },
                        })
                      }
                      rate={rate}
                      period_type={period_type}
                      principalAmount={Number(principal_amount)}
                      setExtraInfo={setExtraInfo}
                    />
                  );
                }
              )}
            </div>
          </div>
          <div>
            <p className="mt-3">Select an interest payment plan:</p>
            <div
              className="form-check mb-3 cursor-pointer"
              onClick={() => onChangeRepaymentFrequency("Monthly")}
            >
              <input
                className="form-check-input cursor-pointer"
                type="radio"
                checked={rollOverInfo?.repaymentPlan === "Monthly"}
                onChange={() => onChangeRepaymentFrequency("Monthly")}
              />
              <label className="form-check-label cursor-pointer">
                Monthly <br />
                Interest will be paid monthly and principal will be paid at
                maturity
              </label>
            </div>
            <div
              className="form-check mb-3 cursor-pointer"
              onClick={() => onChangeRepaymentFrequency("EndOfTenure")}
            >
              <input
                className="form-check-input cursor-pointer"
                type="radio"
                checked={rollOverInfo?.repaymentPlan === "EndOfTenure"}
                onChange={() => onChangeRepaymentFrequency("EndOfTenure")}
              />
              <label className="form-check-label cursor-pointer">
                End of Tenure <br />
                Both Interest and principal will be paid at maturity
              </label>
            </div>
          </div>
          <hr />
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <p className="mt-4">
                Estimated Returns:{" "}
                {extraInfo?.estimatedReturns &&
                  currency &&
                  formatMoney(Number(extraInfo?.estimatedReturns), currency)}
              </p>
              <p className="mt-3">
                Monthly Interest Payment:{" "}
                {extraInfo?.monthlyInterestRepayment &&
                  currency &&
                  formatMoney(
                    Number(extraInfo?.monthlyInterestRepayment),
                    currency
                  )}
              </p>
            </>
          )}

          <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
            <button
              type="button"
              className="btn btn-sm advancly-nobg-btn mr-3"
              onClick={() => setActiveModal("principalOrInterest")}
            >
              Previous
            </button>
            <button type="submit" className="btn btn-sm advancly-white-btn">
              Next
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (!loading) {
          onCancel();
        }
      }}
      footer={null}
    >
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loader type="lg" />
        </div>
      ) : success ? (
        <div className="d-flex text-center justify-content-center align-items-center flex-column p-4">
          <SuccessIcon />
          <h5 className="page-subtitle my-3">Roll over Successful</h5>
          <p>You have successfully rolled over your investment</p>
          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            onClick={onCancel}
          >
            Done
          </button>
        </div>
      ) : activeModal === "principalOrInterest" ? (
        RollOverPrincipalOrInterest()
      ) : (
        Tenure()
      )}
    </Modal>
  );
};

export default RollOverModal;
