import { TAction } from "../actions";
import { actionType } from "../actionTypes";
import produce from "immer";
import {
  TWalletTopupCurrency,
  TWalletTopupStep,
} from "../../interfaces/walletTopup";
import { TWalletTopUp } from "../../interfaces/walletTopup";

export const initialState: {
  currentStep: TWalletTopupStep;
  currency: TWalletTopupCurrency;
  walletId: number | null;
  amount: number | "";
  reference: string | "";
  minimumTopUpAmounts: { currencyCode: string; minimumAmount: number }[];
} = {
  currentStep: 1,
  currency: "",
  walletId: null,
  amount: "",
  reference: "",
  minimumTopUpAmounts: [],
};

export const walletTopupReducer = produce(
  (state: TWalletTopUp = initialState, action: TAction): TWalletTopUp => {
    switch (action.type) {
      case actionType.SET_MINIMUM_TOP_UP_AMOUNTS:
        state = {
          ...state,
          ...action.payload,
        };
        return state;
      case actionType.SET_WALLET_TOPUP_INFO:
        state = {
          ...state,
          ...action.payload,
        };
        return state;
      case actionType.RESET_WALLET_TOPUP:
        return initialState;
      default:
        return state;
    }
  }
);
