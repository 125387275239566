import React, { useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "./../Alert/Alert.component";
import CustomSelectDropdown from "./../CustomHTMLElements/CustomSelectDropdown";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "./../../apis/apiMethods";
import PageLoader from "../PageLoader/PageLoader.component";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "./../../redux/selectors/userSelector";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import useWorldCountries from "../../hooks/custom-hooks/useWorldCountries";
import {appInsights} from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  city: yupValidators.genericRequired({
    message: "Please enter your city name",
    min: 1,
    max: 128,
  }),
  businessEmail: yupValidators.email,
  country: yupValidators.genericRequired({
    message: "Please choose your country",
  }),
  address: yupValidators.genericRequired({
    message: "Please enter your address",
    min: 1,
    max: 128,
  }),
  postal_code: yupValidators.generic({}),
});

interface IAccountInformation {
  funder: any;
}
const BusinessInformation: React.FC<IAccountInformation> = ({ funder }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = useTypedSelector(selectUser);
  const { setCurrentUser } = useActions();

  const { data, isLoading } = useWorldCountries();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({
    city,
    businessEmail,
    address,
    country,
    postal_code,
  }: {
    city: string;
    businessEmail: string;
    country: string;
    address: string;
    postal_code: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      biz_email: businessEmail,
      address,
      country,
      postal_code,
      city,
    };

    try {
      await postData(apiEndpoints.UPDATE_USER_BUSINESS_INFO, reqBody);

      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        setCurrentUser({ ...user, activated });
      }
      setSuccess("Business information updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: {fileName : BusinessInformation}
      })
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert message={error} />}
        {success && <Alert message={success} type="success" />}
        <div className="row mt-4">
          <div className="col-12">
            <CustomInputField
              type="text"
              label="Business Name"
              readOnly
              defaultValue={funder?.biz_name}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="email"
              label="Business Email"
              maxLength={128}
              {...register("businessEmail")}
              placeholder="Business Email"
              defaultValue={funder?.biz_email}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="text"
              maxLength={128}
              {...register("address")}
              placeholder="Address"
              label="Address"
              errors={errors.address}
              defaultValue={funder?.address}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="text"
              maxLength={128}
              {...register("city")}
              placeholder="City"
              label="City"
              errors={errors.city}
              defaultValue={funder?.city}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomSelectDropdown
              {...register("country")}
              label="Country"
              name="country"
              errors={errors.country}
              defaultValue={funder?.country}
            >
              <option value="">Country</option>
              {data?.map(
                ({
                  name,
                  iso2,
                  id,
                }: {
                  name: string;
                  iso2: string;
                  id: number;
                }) => {
                  return (
                    <option value={iso2} key={id}>
                      {name}
                    </option>
                  );
                }
              )}
            </CustomSelectDropdown>
          </div>
          <div className="col-12 col-md-6">
            <CustomInputField
              type="text"
              maxLength={20}
              {...register("postal_code")}
              placeholder="Postal Code"
              label="Postal Code (optional)"
              errors={errors.postal_code}
            />
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-sm advancly-white-btn"
          disabled={loading || isLoading}
        >
          Update
          {loading && <Loader />}
        </button>
      </form>
    </div>
  );
};

export default BusinessInformation;
