import { useEffect, useRef } from "react";
import * as yup from "yup";
import CustomInputField from "./../../components/CustomHTMLElements/CustomInputField";
import { yupValidators } from "./../../helpers/yupValidators";
import CustomSelectDropdown from "./../../components/CustomHTMLElements/CustomSelectDropdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { appInsights } from "../../components/AppInsight/AppInsight";

interface IEditPermissionsModal {
  user: {
    isActive?: boolean;
    id?: boolean;
  };
}

const schema = yup.object().shape({
  isAdmin: yupValidators.genericRequired({
    message: "Please choose an option",
  }),
});

const EditPermissionsModal: React.FC<IEditPermissionsModal> = ({ user }) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "EditPermissions.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ isActive }: { isActive: string }) => {
    dismissModal();
  };

  return (
    <div
      className="modal fade"
      id="editPermissionsModal"
      tabIndex={-1}
      aria-labelledby="editPermissionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">Update User Permissions</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomInputField
                type="email"
                maxLength={128}
                readOnly
                label="User Email"
                defaultValue="gbengacodes@gmail.com"
              />

              <CustomSelectDropdown
                {...register("isAdmin")}
                label="Give user admin rights?"
                errors={errors.isAdmin}
                defaultValue={user?.isActive === true ? "true" : "false"}
              >
                <option value="">Admin?</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </CustomSelectDropdown>

              <button className="btn btn-sm advancly-btn mt-3 w-100">
                Update User
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditPermissionsModal;
