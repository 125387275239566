import { useEffect } from "react";
import styles from "../OnlineInvestment/InvestmentModalStepTwo/InvestmentModalStepTwo.module.scss";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import {
  selectOfflineInvestment,
  selectSelectedInvestment,
} from "../../../redux/selectors/investmentSelector";
import { useActions } from "./../../../hooks/redux-hooks/useActions";
import { Dispatch, FC, FormEvent, SetStateAction, useState } from "react";
import { TRepayment_Types } from "../../../interfaces/investment";
import formatMoney from "./../../../helpers/formatMoney";
import { InvestmentRangeCard } from "./../../InvestmentRangeCard/InvestmentRangeCard.component";
import useInterestCalculation from "../../../hooks/custom-hooks/useInterestCalculation";

interface IOfflineInvestmentStepTwo {
  setError: Dispatch<SetStateAction<string>>;
}

const OfflineInvestmentStepTwo: FC<IOfflineInvestmentStepTwo> = ({
  setError,
}) => {
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const offlineInvestmentInfo = useTypedSelector(selectOfflineInvestment);

  const [extraInfo, setExtraInfo] = useState<{
    monthlyInterestRepayment: string;
    estimatedReturns: string;
  }>({
    monthlyInterestRepayment: "",
    estimatedReturns: "",
  });

  const { amount, duration, repaymentPlan, currency, numberOfDaysInYear } =
    offlineInvestmentInfo;

  const { setInvestmentStep, setOfflineInvestmentInfo } = useActions();

  const onChangeRepaymentFrequency = (plan: TRepayment_Types) => {
    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      repaymentPlan: plan,
    });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    if (!repaymentPlan) {
      setError("Select a repayment plan");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!duration || duration?.days < 1) {
      setError("Select investment duration");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }
    setInvestmentStep(4);
  };

  const filteredInvestmentRange: any = selectedInvestment?.interest_ranges.find(
    (range: any) =>
      range.lower_amount <= Number(amount) &&
      range.upper_amount >= Number(amount)
  );
  const { data, isError, isLoading } = useInterestCalculation({
    principalAmount: Number(amount),
    interestRate: duration?.interest,
    tenure: duration?.days,
    repaymentPlan,
    numberOfDaysInYear: Number(numberOfDaysInYear),
  });
  useEffect(() => {
    if (data?.maturity_amount && data?.monthly_interest_amount) {
      setExtraInfo({
        estimatedReturns: data?.maturity_amount,
        monthlyInterestRepayment: data?.monthly_interest_amount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.maturity_amount, data?.monthly_interest_amount]);
  useEffect(() => {
    setOfflineInvestmentInfo({
      ...offlineInvestmentInfo,
      numberOfDaysInYear: selectedInvestment?.numberOfDaysInYear,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestment]);

  return (
    <div>
      <h5 className="page-subtitle mb-3">Investment Duration</h5>
      <form onSubmit={onSubmit}>
        <div className="mt-4">
          <p className="form-label">How long do you want to Invest?</p>
          <div className={styles.alignStepTwoBoxes}>
            {filteredInvestmentRange?.interest_rates?.map((investment: any) => {
              const { duration: days, rate, period_type } = investment;
              return (
                <InvestmentRangeCard
                  key={days}
                  duration={duration}
                  isError={isError}
                  isLoading={isLoading}
                  repaymentPlan={repaymentPlan ? repaymentPlan : ""}
                  days={days}
                  onClick={() =>
                    setOfflineInvestmentInfo({
                      ...offlineInvestmentInfo,
                      duration: {
                        days,
                        interest: rate,
                      },
                    })
                  }
                  rate={rate}
                  period_type={period_type}
                  principalAmount={Number(amount)}
                  setExtraInfo={setExtraInfo}
                />
              );
            })}
          </div>
        </div>
        <div>
          <p className="mt-3">Select an interest payment plan:</p>
          <div
            className="form-check mb-3 cursor-pointer"
            onClick={() => onChangeRepaymentFrequency("Monthly")}
          >
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              checked={repaymentPlan === "Monthly"}
              onChange={() => onChangeRepaymentFrequency("Monthly")}
            />
            <label className="form-check-label cursor-pointer">
              Monthly <br />
              Interest will be paid monthly and principal will be paid at
              maturity
            </label>
          </div>
          <div
            className="form-check mb-3 cursor-pointer"
            onClick={() => onChangeRepaymentFrequency("EndOfTenure")}
          >
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              checked={repaymentPlan === "EndOfTenure"}
              onChange={() => onChangeRepaymentFrequency("EndOfTenure")}
            />
            <label className="form-check-label cursor-pointer">
              End of Tenure <br />
              Both Interest and principal will be paid at maturity
            </label>
          </div>
        </div>
        <hr />
        <p className="mt-4">
          Estimated Returns:{" "}
          {extraInfo?.estimatedReturns &&
            currency &&
            formatMoney(Number(extraInfo?.estimatedReturns), currency)}
        </p>
        <p className="mt-3">
          Monthly Interest Payment:{" "}
          {extraInfo?.monthlyInterestRepayment &&
            currency &&
            formatMoney(Number(extraInfo?.monthlyInterestRepayment), currency)}
        </p>

        <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
          <button
            type="button"
            className="btn btn-sm advancly-nobg-btn mr-3"
            onClick={() => setInvestmentStep(2)}
          >
            Previous
          </button>
          <button type="submit" className="btn btn-sm advancly-white-btn">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
export default OfflineInvestmentStepTwo;
